import React, { createContext, useState } from "react";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [addId, setAddId] = useState("")

    return (
        <AuthContext.Provider
            value={{ addId, setAddId }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };