import { useContext, useEffect } from 'react';
import { AuthContext } from '../context/authContext';

const FacebookPixel = () => {
    const { addId } = useContext(AuthContext); // Use context to get the id

    useEffect(() => {
        if (addId && addId.success === true && addId.fbId) {
            // Facebook Pixel initialization
            (function (f, b, e, v, n, t, s) {
                if (f.fbq) return;
                n = f.fbq = function () {
                    n.callMethod
                        ? n.callMethod.apply(n, arguments)
                        : n.queue.push(arguments);
                };
                if (!f._fbq) f._fbq = n;
                n.push = n;
                n.loaded = !0;
                n.version = '2.0';
                n.queue = [];
                t = b.createElement(e);
                t.async = !0;
                t.src = v;
                s = b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t, s);
            })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

            // Initialize and track Facebook Pixel with user's ID
            window.fbq('init', addId.fbId);
            window.fbq('track', 'PageView');
        }

        // Cleanup to remove fbq if needed
        return () => {
            if (window.fbq) {
                delete window.fbq;
            }
        };
    }, [addId]);

    return null;
};

export default FacebookPixel;
