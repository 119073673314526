import Box from "@mui/material/Box";
import { useEffect, useState, useRef, useContext, useCallback } from "react";
import { IconButton, Typography } from "@mui/material";
import CardComponent from "../../component/card";
import {
  getQuestionData,
  saveData,
  saveUserVisitData,
} from "../../service/saveData";
import PageLoader from "../../component/pageLoader/pageLoader";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { useNavigate, useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { AuthContext } from "../../context/authContext";

export function Mainpage() {
  const { id } = useParams();
  const { setAddId } = useContext(AuthContext);
  const { addId } = useContext(AuthContext);
  const [active, setActive] = useState(0);
  const [userDocumentId, setUserDocumentId] = useState(null);
  const [privacyCheckbox, setPrivacyCheckbox] = useState("No");
  const [themeColor, setThemeColor] = useState("#37d4fd");
  const [headerTitle, setHeaderTitle] = useState("");
  const [attentionText, setAttentionText] = useState()
  const [googleId, setGoogleId] = useState(null)
  const [video, setVideo] = useState(
    "https://s3-eu-west-1.amazonaws.com/storage-playfilm-public/media/3/users/19048/resources/2231109/clips/city6_qua_standar.mp4"
  );
  const [category, setCategory] = useState([
    {
      question: "Rellena el formulario y nos pondremos en contacto contigo",
      type: "query",
      answerKey: "name",
    },
    {
      question: "Rellena el formulario y nos pondremos en contacto contigo",
      type: "query",
      answerKey: "email",
    },
    {
      question: "Rellena el formulario y nos pondremos en contacto contigo",
      type: "query",
      answerKey: "phone",
    },
    {
      question: "Rellena el formulario y nos pondremos en contacto contigo",
      type: "query",
      answerKey: "postCode",
    },
    {
      question: "Rellena el formulario y nos pondremos en contacto contigo",
      type: "query",
      answerKey: "schedule",
    },
  ]); // List of questions
  const confirmation = {
    question: "Rellena el formulario y nos pondremos en contacto contigo",
    answerKey: "confirmation",
    type: "confirm",
  };
  const [loading, setLoading] = useState(false);
  const [indexKey, setIndexKey] = useState(0);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidName, setIsValidName] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [isPostCode, setIsPostCode] = useState(true);
  const [browserHeight, setBrowserHeight] = useState(window.innerHeight);
  const [categoryQuestionLength, setCategoryQuestionLength] = useState(0);
  const [muted, setMuted] = useState(true);
  const [disable, setDisable] = useState(false);
  const [changeTimeout, setChangeTimeout] = useState(false);

  const [offerStep, setOfferStep] = useState(1);
  const [offerEmail, setOfferEmail] = useState("");
  const [isOfferEmailValid, setIsOfferEmailValid] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState(
    "¡Hola! 👋🏼  Qué bueno verte por aquí"
  );

  // const [q0_1, setQ0_1] = useState("");
  // const [q0_2, setQ0_2] = useState("");
  // const [q0_3, setQ0_3] = useState("");

  const [ip, setIp] = useState("");
  const [landingCode, setLandingCode] = useState("");
  const [adName, setAdName] = useState("");
  const [campaignId, setCampaignId] = useState("");
  const [advertisementId, setAdvertisementId] = useState("");
  const [startDate, setStartDate] = useState("");

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const videoRef = useRef(null);

  useEffect(() => {
    if (id) {
      getData(id);
      getIpAddress();
      setStartDate(new Date());
    }
  }, [id]);


  useEffect(() => {
    if (googleId) {
      const script1 = document.createElement("script");
      script1.async = true;
      script1.src = "https://www.googletagmanager.com/gtag/js?id=" + googleId;
      document.head.appendChild(script1);

      const script2 = document.createElement("script");
      script2.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${googleId}');
          `;
      document.head.appendChild(script2);
    }
  }, [googleId]);

  const getData = async (id) => {
    setLoading(true);
    const result = await getQuestionData(id);

    const fieldConfig = result.data?.question_config;

    const filteredCategoryBefore = [];
    const filteredCategoryAfter = [];

    category.forEach((item) => {
      const key = item.answerKey.toLowerCase();
      const config = fieldConfig[key];

      if (config?.show === 1) {
        const { answerKey, question, type } = item;
        const filteredItem = {
          answerKey,
          question,
          type,
          verify: config.verify || 0,
        };

        if (config.place === "before") {
          filteredCategoryBefore.push(filteredItem);
        } else if (config.place === "after") {
          filteredCategoryAfter.push(filteredItem);
        }
      }
    });

    // Sort both categories by order
    filteredCategoryBefore.sort((a, b) => {
      const orderA = fieldConfig[a.answerKey.toLowerCase()]?.order || 0;
      const orderB = fieldConfig[b.answerKey.toLowerCase()]?.order || 0;
      return orderA - orderB;
    });

    filteredCategoryAfter.sort((a, b) => {
      const orderA = fieldConfig[a.answerKey.toLowerCase()]?.order || 0;
      const orderB = fieldConfig[b.answerKey.toLowerCase()]?.order || 0;
      return orderA - orderB;
    });

    if (result.success) {
      if (result.data.video_url) {
        setVideo(result.data.video_url);
        setAttentionText(result.data.page_attention)
      } else {
        setVideo(
          "https://s3-eu-west-1.amazonaws.com/storage-playfilm-public/media/3/users/19048/resources/2231109/clips/city6_qua_standar.mp4"
        );
      }
      setHeaderTitle(result.data.pageTitle);
      setThemeColor(result.data.colorCode);

      // Set category by combining filteredCategoryBefore, result.data.category, and filteredCategoryAfter
      setCategory([
        ...filteredCategoryBefore,
        ...result.data.category,
        ...filteredCategoryAfter,
        confirmation,
      ]);

      setCategoryQuestionLength(result.data.category.length);
      if (result.data.gid) {
        setGoogleId(result.data.gid)
      }

      if (result.data.fbid || result.data.ttq) {
        setAddId({ success: true, fbId: result.data.fbid, ttq: result.data.ttq });
      } else {
        setAddId({ success: false });
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [video]);

  useEffect(() => {
    const campaign_id = searchParams.get("campaign_id");
    const ad_id = searchParams.get("ad_id");
    const landing_code = searchParams.get("landing_code");
    const ad_name = searchParams.get("ad_name");
    setLandingCode(landing_code);
    setAdName(ad_name);
    setAdvertisementId(ad_id);
    setCampaignId(campaign_id);
  }, [searchParams]);

  const getIpAddress = () => {
    fetch("https://api.ipify.org?format=json", {
      method: "GET",
      headers: {},
    })
      .then((res) => res.json())
      .then(async (data) => {
        setIp(data.ip);
      });
  };
  const [question_input_data, setQuestion_input_data] = useState({});
  const [data, setData] = useState({});
  const [value, setValue] = useState({
    name: "",
    email: "",
    phone: "",
    postCode: "",
    schedule: "",
  });

  useEffect(() => {
    const handleResize = () => {
      let heightWithoutUrlBar = window.innerHeight;

      const isIOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

      if (isIOS) {
        const isUrlBarVisible =
          window.innerHeight === document.documentElement.clientHeight;
        if (isUrlBarVisible) {
          heightWithoutUrlBar = document.documentElement.clientHeight;
        }
      }

      setBrowserHeight(heightWithoutUrlBar);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (disable) {
      setPrivacyCheckbox("Yes");
    } else {
      setPrivacyCheckbox("No");
    }
  }, [disable]);

  const SaveCampaign = async () => {
    const userData = getUserData();
    const { privacyCheckbox, ...saveData } = userData;
    const result = await saveUserVisitData(saveData);
  };

  useEffect(() => {
    if (ip && searchParams) {
      SaveCampaign();
    }
  }, [ip, searchParams]);

  const clickNow = async (ans, index) => {
    const currentQuestion = category[index];
    const answerKey =
      currentQuestion.type === "question" ||
        currentQuestion.type === "question_input"
        ? `q${indexKey + 1}`
        : currentQuestion.answerKey || "default";

    let newData = { ...data };
    let valid = true;

    if (currentQuestion.type === "question") {
      newData[answerKey] = ans.desc;
      setIndexKey(indexKey + 1);
      // if (index === categoryQuestionLength - 1) {
      //   setLoadingMsg(
      //     "Gracias por tu paciencia. Estamos buscando la oferta que mejor se adapte a tus necesidades…"
      //   );
      //   setLoading(true);
      //   setTimeout(() => {
      //     setLoading(false);
      //   }, 3000);
      // }
    } else if (currentQuestion.type === "question_input") {
      newData[answerKey] = ans;
      setIndexKey(indexKey + 1);
    } else if (currentQuestion.type === "query") {
      switch (answerKey) {
        case "name":
          if (value.name) {
            newData.name = value.name;
          } else {
            setIsValidName(false);
            valid = false;
          }
          break;
        case "email":
          if (
            /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(value.email).toLowerCase())
          ) {
            newData.email = value.email;
          } else {
            setIsValidEmail(false);
            valid = false;
          }
          break;
        case "phone":
          if (value.phone) {
            newData.telephone = value.phone;
          } else {
            setIsValidPhone(false);
            valid = false;
          }
          break;
        case "postCode":
          if (value.postCode) {
            newData.postCode = value.postCode;
          } else {
            setIsPostCode(false);
            valid = false;
          }
          break;
        case "schedule":
          if (value.schedule) {
            newData.schedule = value.schedule;
          } else {
            valid = false;
          }
          break;
        default:
          break;
      }
    }
    if (valid) {
      setData(newData);
      setActive(index + 1);
      if (index === category.length - 1) {
        await saveUserData();
      }
    }
  };

  const saveUserData = async () => {
    if (value.schedule) {
      setLoadingMsg("Estamos enviando su información 🛫");
      setLoading(true);
      const userData = {
        client: id,
        // q0_1: q0_1,
        push: "m",
        // ...(q0_2 && { q0_2: q0_2 }),
        // ...(q0_3 && { q0_3: q0_3 }),
        ...data,
        // ...question_input_data,
        schedule: value.schedule,
        privacyCheckbox: privacyCheckbox,
        IP: ip,
        start_time: startDate,
        end_time: new Date(),
        ...(campaignId && { campaign_id: campaignId }),
        ...(advertisementId && { ad_id: advertisementId }),
        ...(adName && { ad_name: adName }),
        ...(landingCode && { landing_code: landingCode }),
        ...(userDocumentId && { user_document_id: userDocumentId }),
      };
      console.log("userData: ", userData);
      const result = await saveData(userData);
      if (result.status === "success") {
        console.log("result: ", result.data);
        setUserDocumentId(result.data.id);
        if (addId.success === true) {
          if (addId.fbId) {
            window.fbq("track", "CompleteRegistration");
            console.log("CompleteRegistration:Facebook ");
          }
          if (addId.ttq) {
            console.log("CompleteRegistration:ttq ");
            window.ttq.track("CompleteRegistration");
          }
          if (googleId) {
            console.log("CompleteRegistration:gtag ");
            window.gtag('event', 'conversion', {
              'send_to': googleId + '/dVKKCPjM89UZELLF--49',
              'transaction_id': '',
            });
          }
        }
        setLoading(false);
        navigate("/success?=" + id);
      } else {
        setLoading(false);
      }
    }
  };

  const getUserData = useCallback(() => {
    return {
      client: id,
      // q0_1: q0_1,
      push: "a",
      // ...(q0_2 && { q0_2: q0_2 }),
      // ...(q0_3 && { q0_3: q0_3 }),
      ...data,
      ...(value.schedule && { schedule: value.schedule }),
      privacyCheckbox: privacyCheckbox,
      IP: ip,
      start_time: startDate,
      ...(campaignId && { campaign_id: campaignId }),
      ...(advertisementId && { ad_id: advertisementId }),
      ...(adName && { ad_name: adName }),
      ...(landingCode && { landing_code: landingCode }),
      ...(userDocumentId && { user_document_id: userDocumentId }),
    };
  }, [
    id,
    // q0_1,
    // q0_2,
    // q0_3,
    data,
    value.schedule,
    privacyCheckbox,
    ip,
    startDate,
    campaignId,
    advertisementId,
    adName,
    landingCode,
    userDocumentId,
  ]);

  // Function to auto save data
  const autoSave = useCallback(async () => {
    const userData = getUserData();
    try {
      const result = await saveData(userData);
      if (result.status === "success") {
        setUserDocumentId(result.data.id);
      } else {
        console.log("Error saving data:", result.error);
      }
    } catch (error) {
      console.error("Error in autoSave function:", error);
    }
  }, [getUserData, saveData]);

  // Effect to handle timeout based on q0_1 change
  useEffect(() => {
    let timeout;
    if (ip) {
      timeout = setTimeout(() => {
        setChangeTimeout(true);
      }, 300000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [ip]);

  // Effect to trigger autoSave on timeout
  useEffect(() => {
    if (changeTimeout) {
      autoSave();
      setChangeTimeout(false);
    }
  }, [changeTimeout, autoSave]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      autoSave();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [autoSave]);

  // const handleOffer = () => {
  //   setLoadingMsg(
  //     "Vale, vamos hacerte 3 preguntas para conseguirte la mejor oferta…"
  //   );
  //   if (offerStep === 1) {
  //     setOfferStep(2);
  //     setQ0_1("no");
  //   } else if (offerStep === 2) {
  //     setOfferStep(3);
  //     setQ0_2("no");
  //   } else if (offerStep === 3) {
  //     if (
  //       offerEmail &&
  //       /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(offerEmail).toLowerCase())
  //     ) {
  //       setOfferStep("end");
  //       setQ0_3(offerEmail);
  //       setLoading(true);
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 3000);
  //     } else {
  //       setIsOfferEmailValid(true);
  //     }
  //   }
  // };

  // const handleEnd = () => {
  //   setLoadingMsg(
  //     "Vale, vamos hacerte 3 preguntas para conseguirte la mejor oferta…"
  //   );
  //   if (offerStep === 1) {
  //     setOfferStep("end");
  //     setLoading(true);
  //     setTimeout(() => {
  //       setLoading(false);
  //     }, 3000);
  //     setQ0_1("yes");
  //   } else if (offerStep === 2) {
  //     setOfferStep("end");
  //     setLoading(true);
  //     setTimeout(() => {
  //       setLoading(false);
  //     }, 3000);
  //     setQ0_2("yes");
  //   }
  // };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          width: "100%",
          height: { md: "100vh", xs: `${browserHeight}px` },
        }}
      >
        {/* {offerStep === "end" ?
                    <> */}
        <video
          id="bg"
          width="100%"
          height="100%"
          style={{ objectFit: "cover", position: "absolute" }}
          autoPlay
          muted
          controls={false}
          playsInline
          loop
          ref={videoRef}
        >
          <source src={video} type="video/mp4" />
        </video>

        <Box id="trama-image" />

        <Box
          sx={{
            width: "100%",
            maxWidth: "720px",
            marginInline: "auto",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            boxShadow: "0px 22px 30px #000",
          }}
        >
          <Box sx={{ flexGrow: 1, position: "relative" }}>
            <video
              width="100%"
              height="100%"
              style={{ objectFit: "cover", position: "absolute" }}
              autoPlay
              muted={muted}
              controls={false}
              playsInline
              loop
              ref={videoRef}
            >
              <source src={video} type="video/mp4" />
            </video>

            {/* {offerStep === "end" ? (
              <> */}
            {category.map((item, index) => (
              <>
                {index === active && (
                  <CardComponent
                    isValidEmail={isValidEmail}
                    isValidName={isValidName}
                    isValidPhone={isValidPhone}
                    isPostCode={isPostCode}
                    themeColor={themeColor}
                    index={index}
                    setValue={setValue}
                    setQuestion_input_data={setQuestion_input_data}
                    question_input_data={question_input_data}
                    value={value}
                    clickNow={clickNow}
                    disable={disable}
                    setDisable={setDisable}
                    item={item}
                    type={item.type}
                    question={item.question}
                    answerKey={item.answerKey}
                    attentionText={attentionText}
                  />
                )}

                {item.type === "query" && index === active && (
                  <Typography
                    sx={{
                      position: "absolute",
                      top: 120,
                      right: 0,
                      left: 0,
                      marginInline: "auto",
                      textAlign: "center",
                      color: "white",
                      zIndex: 100,
                    }}
                  >
                    Muchas gracias por tus respuestas
                  </Typography>
                )}
              </>
            ))}
            {/* </>
            ) : (
              <>
                {offerStep === 1 && (
                  <CardComponent
                    themeColor={themeColor}
                    question="¿Te gustaria conocer una oferta diseña exclusivamente para ti?"
                    type={"offerType"}
                    handleEnd={handleEnd}
                    handleOffer={handleOffer}
                  />
                )}

                {offerStep === 2 && (
                  <CardComponent
                    themeColor={themeColor}
                    question={
                      "Qué lástima, porque hoy tenemos ofertas especiales que acaban pronto…\n\r \n\r¿Quieres conocerlas?"
                    }
                    type={"offerType"}
                    handleEnd={handleEnd}
                    handleOffer={handleOffer}
                  />
                )}

                {offerStep === 3 && (
                  <CardComponent
                    themeColor={themeColor}
                    question={
                      "Gracias por tu atención, puedes recibir novedades de ofertas por email."
                    }
                    type={"offerType"}
                    sendMail={handleOffer}
                    isOfferEmailValid={isOfferEmailValid}
                    offerEmail={offerEmail}
                    setOfferEmail={setOfferEmail}
                  />
                )}
              </>
            )} */}

            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                py: "8px",
                fontSize: "13px",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                color: "#fff",
                textAlign: "left",
                lineHeight: "16px",
                zIndex: 3,
              }}
            >
              {headerTitle && (
                <Typography px={1} variant="caption">
                  {headerTitle}
                </Typography>
              )}
            </Box>

            <Box id="trama-image" />

            <Box
              sx={{
                position: "absolute",
                top: 40,
                left: 20,
                zIndex: 3,
              }}
            >
              <IconButton color="inherit" onClick={() => setMuted(!muted)}>
                {muted ? (
                  <VolumeOffIcon sx={{ color: "white" }} />
                ) : (
                  <VolumeUpIcon sx={{ color: "white" }} />
                )}
              </IconButton>
            </Box>
            {loading && (
              <PageLoader loadingMsg={loadingMsg} themeColor={themeColor} />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
